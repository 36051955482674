import { Flex, Form, FormItemProps, Input, Modal } from 'antd';
import { DarkButton } from 'components/dark-button';
import { Text } from 'components/mvp-typography';
import { ErrorDisplay } from 'features/patient/assessment/questionnaire/ErrorDisplay';
import { ConcussionRXLogo } from 'features/sign-in/v2/concussionRx-logo';
import { styles } from 'features/sign-in/v2/styles';
import { CSSProperties, ReactNode, useState } from 'react';
import { resolveError } from 'utils/formatters/error/resolve-error';

interface FormArgs {
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * A typed form item
 *
 * This is a new function instead of an alias because eslint was throwing a parsing error
 */
const FormItem = (props: FormItemProps<FormArgs>) => <Form.Item {...props} />;

export default function UserAddModalWrapper(props: {
  onAddUser: (data: FormArgs) => Promise<void | any> | void | any;

  /**
   * The type of user to create. Must be in the singular form
   */
  type: string;
  containerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
}) {
  const typeToCreate = props.type.toLowerCase();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div style={props.containerStyle}>
      <DarkButton
        style={props.buttonStyle}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Add {typeToCreate}
      </DarkButton>
      <UserEditableDetailsModal
        {...props}
        onSubmit={props.onAddUser}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        submitText={`Create ${typeToCreate}`}
        actionProcessingDescription={`Sending invitation. Please do not close or reload this window or tab.`}
      />
    </div>
  );
}

export function UserEditableDetailsModal(props: {
  onSubmit: (data: FormArgs) => Promise<void | any> | void | any;
  isOpen: boolean;
  setIsOpen: (s: boolean) => void;
  submitText: ReactNode;
  actionProcessingDescription: ReactNode;
  initialValues?: Record<string, string>;
}) {
  const [error, setError] = useState<Error | null>(null);
  const [isActionProcessing, setIsActionProcessing] = useState(false);

  async function onAddUserSubmitEvent(event: FormArgs) {
    setIsActionProcessing(true);
    try {
      await props.onSubmit(event);
      props.setIsOpen(false);
      setError(null);
    } catch (e) {
      setError(resolveError(e));
    } finally {
      setIsActionProcessing(false);
    }
  }

  return (
    <Modal
      open={props.isOpen}
      onCancel={() => props.setIsOpen(false)}
      closable={!isActionProcessing}
      footer={() => <></>}
    >
      <Flex
        style={{
          ...styles.titleContainer
        }}
        justify="center"
      >
        <div
          style={{
            maxWidth: 300
          }}
        >
          <ConcussionRXLogo />
        </div>
      </Flex>
      <Form
        onFinish={onAddUserSubmitEvent}
        disabled={isActionProcessing}
        layout="vertical"
        initialValues={props.initialValues}
      >
        <FormItem
          label="First name"
          name="firstName"
          rules={[{ required: true }]}
        >
          <Input />
        </FormItem>

        <FormItem
          label="Last name"
          name="lastName"
          rules={[{ required: true }]}
        >
          <Input />
        </FormItem>

        <FormItem
          label="Email"
          name="email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input />
        </FormItem>
        <FormItem>
          <div>
            <DarkButton
              htmlType="submit"
              loading={isActionProcessing}
              style={{
                padding: '1rem 2rem',
                height: 'auto'
              }}
            >
              {props.submitText}
            </DarkButton>
            {isActionProcessing && (
              <Text>{props.actionProcessingDescription}</Text>
            )}
          </div>
        </FormItem>
      </Form>

      {error && (
        <ErrorDisplay
          title="Something went wrong"
          description=""
          error={error}
          style={{
            width: '100%'
          }}
        />
      )}
    </Modal>
  );
}
