import { ConfigProvider, Flex, Table } from 'antd';
import { createRepo } from 'api/firebase/create-repo';
import {
  adminQueryPatientAssessmentStatus,
  db,
  PatientInfo
} from 'api/firebase/firebase-api';
import { useAppSelector } from 'app/rootReducer';
import { Text, Title } from 'components/mvp-typography';
import { PrintableRootPage } from 'components/page/page-2';
import { fetchPatientData } from 'components/patient-info/patientInfoSlice';
import { PatientRoutePattern } from 'components/route-switch/route-switch-patient/patient-route-helpers';
import { CrxSearch } from 'components/search-component/search-component';
import { Clinician } from 'documents/clinician';
import { Collection } from 'documents/document';
import { User } from 'features/auth/authSlice';
import { uniqBy } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { resolveError } from 'utils/formatters/error/resolve-error';
import { doesUserHaveAssessments } from 'utils/user-has-assessments';
import './clinician-home-v2.scss';

function useClinicianInfo() {
  const { user, isUserDataLoading } = useAppSelector(s => s.auth);
  const [clinicianInfo, setClinicianInfo] = useState<Clinician | null>(null);
  useEffect(() => {
    async function setup(user: User | null, isUserDataLoading: boolean) {
      if (isUserDataLoading) return;
      if (!user) throw new Error(`You must be logged in`);

      const clinicianRepo = createRepo<Clinician>(Collection.Clinicians);
      const _clinicianInfo = await clinicianRepo.find(user.uid);
      if (!_clinicianInfo)
        throw new Error(`Clinician (id=${user.uid}) not found`);
      setClinicianInfo(_clinicianInfo);
    }
    setup(user, isUserDataLoading);
  }, [user, isUserDataLoading]);
  return clinicianInfo;
}

export default function ClinicianHomeV2() {
  const [rawAPITableData, setRawAPITableData] = useState<{
    data: PatientInfo[];
    isLoading: boolean;
  }>({ data: [], isLoading: true });
  const [state_error, state_setError] = useState<Error | null>(null);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const history = useHistory();
  const clinicianInfo = useClinicianInfo();

  useEffect(() => {
    /**
     * Stop if the clinician information is not loaded
     */
    if (clinicianInfo === null) return;
  }, [clinicianInfo]);

  /**
   * A handler that runs when a patient is selected in the table
   * @param args
   * @returns
   */
  async function onSelectPatient(args: { patientEmail: string }) {
    try {
      state_setError(null);
      // Don't do anything if the input is empty
      if (args.patientEmail.length === 0) return;

      const result = await db
        .collection(Collection.Patients)
        .where('email', '==', args.patientEmail)
        .get();

      // Creates a list of ids
      const ids = result.docs.map(_ => _.id);
      if (ids.length === 0) {
        throw new Error(`No user with (${args.patientEmail}) found.`);
      }

      if (ids.length !== 1) {
        throw new Error(
          `Email (${args.patientEmail}) returned multiple users.`
        );
      }

      // Get the data of the patient
      const patientData = await fetchPatientData(ids[0]);

      // Patients can only be accessed if they have at least one assessment
      // Validate if the user has an assessment
      const userHasData = doesUserHaveAssessments({
        dhi: patientData.dhi,
        impact: patientData.impactTests,
        promis: patientData.promis
      });

      if (!userHasData) {
        throw new Error(
          `MISSING DATA. This user needs to complete at least one assessment.`
        );
      }

      history.push(generatePath(PatientRoutePattern, { patientId: ids[0] }));
    } catch (_error) {
      const error = resolveError(_error);
      state_setError(
        new Error(
          [
            error.message.trim(),
            `If you think this is an error please wait a few moments and try again, or contact ConcussionRX.`.trim()
          ].join(' ')
        )
      );
    }
  }

  useEffect(() => {
    async function setup() {
      state_setError(null);
      if (clinicianInfo === null) return;
      try {
        const apiArgs: Parameters<typeof adminQueryPatientAssessmentStatus>[0] =
          {
            clinicId: clinicianInfo.clinics[0],
            usersLimit: 10,
            pendingUsersLimit: 10
          };
        let apiResponse = await adminQueryPatientAssessmentStatus(apiArgs);

        while (
          apiResponse.data.inClinic.length !== 0 ||
          apiResponse.data.pendingInClinic.length !== 0
        ) {
          const fixedArr = [
            ...apiResponse.data.inClinic,
            ...apiResponse.data.pendingInClinic
          ];
          setRawAPITableData(oldState => ({
            data: uniqBy([...oldState.data, ...fixedArr], _ => _.id),
            isLoading: false
          }));
          apiArgs.usersLastDocId = apiResponse.data.inClinic.pop()?.id;
          apiArgs.pendingUsersLastDocId =
            apiResponse.data.pendingInClinic.pop()?.id;
          if (!apiArgs.usersLastDocId) {
            delete apiArgs.usersLastDocId;
          }
          if (!apiArgs.pendingUsersLastDocId) {
            delete apiArgs.pendingUsersLastDocId;
          }
          apiResponse = await adminQueryPatientAssessmentStatus(apiArgs);
        }
      } catch (e) {
        console.error(
          `Failed to fetch ${adminQueryPatientAssessmentStatus.name} API`
        );
        console.error(e);
        state_setError(e as Error);
      }
    }
    setup();
  }, [clinicianInfo]);

  /**
   * Format the data and apply a search filter
   */
  const filteredData: (PatientInfo & {
    signUpDate: Moment;
  } & PatientInfo['__assessmentData__'])[] = useMemo(() => {
    // Only show patients that have a completed assessment
    const _data = rawAPITableData.data
      .filter(_ => _.__assessmentData__.lastCompletedAssessment !== null)
      .map(_ => ({
        ..._,
        lastCompletedAssessment:
          _.__assessmentData__.lastCompletedAssessment !== null
            ? moment(_.__assessmentData__.lastCompletedAssessment)
            : null,
        recentAssessmentDate:
          _.__assessmentData__.lastCompletedAssessment !== null &&
          _.__assessmentData__.recentAssessmentDate !== null
            ? moment(_.__assessmentData__.recentAssessmentDate)
            : null,

        signUpDate: moment(_.metadata?.created)
      }));

    /**
     * There is no search to filter by
     */
    if (searchQuery === null) return _data;
    return _data.filter(_ =>
      JSON.stringify({
        displayName: _.displayName,
        email: _.email
        // patientId: _.patientId
      })
        .toLowerCase()
        .includes(searchQuery)
    );
  }, [rawAPITableData, searchQuery]);

  return (
    <ConfigProvider prefixCls="crx-clinician-home">
      <PrintableRootPage title="Patient Assessments" hideTitle>
        <Flex justify={'space-between'}>
          <Title>Patients</Title>
          <div>
            <CrxSearch
              onSearch={val => {
                if (val === '') {
                  setSearchQuery(null);
                  return;
                }
                setSearchQuery(val);
              }}
              placeholder={'Search patients'}
            />
          </div>
        </Flex>
        <Table
          loading={rawAPITableData.isLoading}
          dataSource={filteredData}
          sortDirections={['descend', 'ascend']}
          columns={[
            {
              title: 'Name',
              dataIndex: 'displayName',
              sorter: (a, b) => a.displayName.localeCompare(b.displayName)
            },
            {
              title: 'Email',
              dataIndex: 'email',
              sorter: (a, b) => a.email.localeCompare(b.email)
            },
            {
              title: 'Sign up date',
              dataIndex: 'signUpDate',
              render: (val: moment.Moment) => val && val.format('YYYY-MM-DD'),
              sorter: (a, b) =>
                Number(a.signUpDate?.unix() ?? 0) -
                Number(b.signUpDate?.unix() ?? 0)
            },
            {
              title: 'Last completed assessment',
              dataIndex: 'lastCompletedAssessment',
              render: (val: moment.Moment | null) =>
                val === null ? val : val.format('YYYY-MM-DD'),
              sorter: (a, b) =>
                Number(a.lastCompletedAssessment?.unix() ?? 0) -
                Number(b.lastCompletedAssessment?.unix() ?? 0)
            },
            {
              title: 'Recent assessment date',
              dataIndex: 'recentAssessmentDate',
              render: (val: moment.Moment | null) =>
                val === null ? val : val.format('YYYY-MM-DD'),
              sorter: (a, b) =>
                Number(a.recentAssessmentDate?.unix() ?? 0) -
                Number(b.recentAssessmentDate?.unix() ?? 0)
            }
          ]}
          onRow={record => ({
            onClick: async () => {
              setRawAPITableData(_ => ({ ..._, isLoading: true }));
              await onSelectPatient({ patientEmail: record.email });
              setRawAPITableData(_ => ({ ..._, isLoading: false }));
            }
          })}
          rowHoverable={false}
        />

        {
          /**
           * Display an error message if the data fails to load
           */
          state_error && (
            <Text color={'red'}>
              An eror occured when loading data. {state_error?.message} If you
              think this is an error please wait a few moments and try again, or
              contact ConcussionRX.
            </Text>
          )
        }
      </PrintableRootPage>
    </ConfigProvider>
  );
}
